import { useCallback } from 'react';

const tContainer = {
  t: key => key,
};

const initializeT = translationFunction => {
  tContainer.t = translationFunction;
};

const useT = () => useCallback(tContainer.t);

export { initializeT, useT };
