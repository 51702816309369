import uniq from 'lodash/uniq';
import i18next from 'i18next';
import i18nBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import useAvailableLanguages from 'ui/customHooks/useAvailableLanguages';

const lngDetector = new LanguageDetector();

const createI18next = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const availableLanguages = useAvailableLanguages();
  const availableLanguageCodes = availableLanguages.map(language => language.languageCode);
  const defaultLang = availableLanguages.find(language => language.isDefault).languageCode;

  const getIsLanguageCodeSupported = languageCode =>
    availableLanguageCodes.includes(languageCode.split('-')[0].toLowerCase());

  lngDetector.addDetector({
    name: 'customLangDetector',
    lookup: () => {
      const isNavigatorDefined = typeof navigator !== 'undefined';
      const navigatorLanguages = isNavigatorDefined
        ? [
            ...(navigator.languages || []),
            navigator.browserLanguage,
            navigator.userLanguage,
            navigator.language,
            navigator.systemLanguage,
          ].filter(Boolean)
        : [];

      const supportedLanguageCodes = navigatorLanguages
        .filter(getIsLanguageCodeSupported)
        .map(language => language.split('-')[0]);

      const isAnyNavigatorLanguageSupported = supportedLanguageCodes.length > 0;
      if (!isAnyNavigatorLanguageSupported) {
        const defaultLang = availableLanguages.find(language => language.isDefault).languageCode;
        return [defaultLang];
      }
      return uniq(supportedLanguageCodes);
    },
  });

  i18next
    .use(initReactI18next)
    .use(i18nBackend)
    .use(lngDetector)
    .init({
      interpolation: { escapeValue: false },
      backend: {
        loadPath: `${process.env.BACKEND_URL}/locales/get/{{lng}}/{{ns}}`,
        addPath: `${process.env.BACKEND_URL}/locales/add/{{lng}}/{{ns}}`,
      },
      detection: {
        order: ['cookie', 'localStorage', 'customLangDetector'],
        lookupCookie: 'palyxi18next',
        lookupLocalStorage: 'palyxi18nextLng',
        lookupSessionStorage: 'palyxi18nextLng',
      },
      react: {
        wait: true,
        useSuspense: false,
      },
      fallbackNS: 'common',
      defaultNS: 'common',
      fallbackLng: defaultLang,
      saveMissing: false,
      lowerCaseLng: false,
      whitelist: availableLanguageCodes,
    });

  return i18next;
};

export default createI18next;
