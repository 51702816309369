import gql from 'graphql-tag';
import i18next from 'i18next';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import useConfig from 'UI/customHooks/useConfig';
import { clientLogger, navigateInternal } from 'UI/lib';
import { AUTH_ERROR_CODE_QUERY_PARAM, AUTH_ERROR_REASON_QUERY_PARAM } from '../../auth/constants';
import { getBrowserUserCredentials, logoutUser } from '../../auth/userCredentials';
import getApolloClient from '../../getApolloClient';
import { setLastActionTimestamp } from '../lib/local-storage';
import {
  CHANGE_USER_PASSWORD,
  CREATE_USER_WITH_PASSWORD,
  LOGIN_USER_WITH_PASSWORD,
  REQUEST_RESET_PASSWORD_TOKEN,
  RESET_USER_PASSWORD,
  UPDATE_USER_ONBOARDING_STATUS,
  USER_PROFILE_UPDATE,
  USER_UPDATE_AVATAR,
} from '../mutations/user';
import { GET_VIEWER } from '../queries/viewer';
import getIsOnboardingNeeded from './getIsOnboardingNeeded';

const INITIAL_STATE = {
  currentUser: null,
  loginError: null,
  isUserBeingLoaded: false,
  isSessionEnded: false,
};

export const UserContext = React.createContext();

class UserProviderComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    let organizationToken = null;

    if (props.location.search) {
      organizationToken = queryString.parse(props.location.search).token;
    }

    this.state = {
      organizationToken,
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    const { currentUser } = this.state;

    if (!currentUser) {
      try {
        this.getCurrentUser();
      } catch (error) {
        clientLogger.error('Error while resolving user', error);
      }
    }
  }

  getAuthErrorReason = error => {
    const switchMessage = {
      UNAUTHORIZED: this.props.t('auth:login_invalid_credentials'),
      EMAIL_EXISTS: this.props.t('auth:error_email_exists'),
      TOKEN_INVALID: this.props.t('auth:error_token_invalid'),
      FORBIDDEN: this.props.t('auth:error_forbidden'),
      ACCOUNT_LOCKED: this.props.t('auth:error_account_locked'),
    };

    return (
      switchMessage[error?.graphQLErrors?.[0]?.message] ||
      switchMessage[error?.message] ||
      this.props.t('auth:unexpected_error')
    );
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!this.userTracked && nextState.currentUser?.profile?.email) {
      this.userTracked = true;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const pegasusConfig = useConfig();
    const { hasIdleFeature } = get(pegasusConfig, 'cms.features', false);
    const isAllowedToAutoLogout = !this.getIsOnboardingNeeded();
    const isAbleToLogout = window.localStorage.palyxToken && !this.state.isSessionEnded;
    const hasAutoLogoutFeature = Boolean(hasIdleFeature);

    if (hasAutoLogoutFeature && isAbleToLogout && isAllowedToAutoLogout) {
      this.setIdlenessListener();
    }
  }

  logoutDebounced = debounce(() => {
    this.removeIdlenessListener();
    this.setState({ isSessionEnded: true });
    this.logout();
  }, window.clientConfig.idleness.idleTime);

  // debouncing this so we update localstorage once in 1s, on mousemove.
  setLastActionTimestampDebounce = debounce(setLastActionTimestamp, 1000, { leading: true });

  resetIdlenessWatcher = () => {
    this.logoutDebounced();
    this.setLastActionTimestampDebounce(Date.now());
  };

  setIdlenessListener = () => {
    window.document.body.addEventListener('mousemove', this.resetIdlenessWatcher, true);
  };

  removeIdlenessListener = () => {
    window.document.body.removeEventListener('mousemove', this.resetIdlenessWatcher, true);
  };

  getCurrentUser = () => {
    const { palyxToken } = getBrowserUserCredentials();

    if (!palyxToken) {
      return;
    }

    return new Promise(async (resolve, reject) => {
      this.setState({ isUserBeingLoaded: true });
      try {
        const { data, error } = await getApolloClient().query({
          fetchPolicy: 'no-cache',
          query: GET_VIEWER,
        });

        if (error) {
          throw error;
        }

        const { viewer: currentUser } = data;

        if (!currentUser) {
          throw new Error('Failed to load viewer');
        }

        this.setState(
          {
            currentUser,
            isUserBeingLoaded: false,
          },
          () => resolve(currentUser)
        );
      } catch (error) {
        this.setState({ isUserBeingLoaded: false }, () => {
          reject(error);
        });
      }
    }).catch(error => {
      clientLogger.error(error, 'Error while resolving user');
      navigateInternal(
        `/?${AUTH_ERROR_CODE_QUERY_PARAM}=500&${AUTH_ERROR_REASON_QUERY_PARAM}=Not possible to fetch viewer data`
      );
      return;
    });
  };

  userTracked = false;

  onLoginError = error => {
    try {
      const graphQlError = get(error, 'graphQLErrors[0]');
      if (!graphQlError) {
        return false;
      }
      const extensionsCode = get(graphQlError, 'extensions.code');
      if (extensionsCode !== 'SSO_REQUIRED_ERROR') {
        return false;
      }
      const ssoProviderUrl = get(graphQlError, 'extensions.exception.ssoProviderUrl');

      this.setState(
        {
          loginError: {
            ssoProviderUrl,
          },
        },
        () =>
          logoutUser(
            `/?${AUTH_ERROR_CODE_QUERY_PARAM}=403&${AUTH_ERROR_REASON_QUERY_PARAM}=${graphQlError.message}`
          )
      );
      return true;
    } catch (e) {
      return false;
    }
  };

  changeLanguage(language) {
    return i18next.changeLanguage(language, async err => {
      if (err) {
        clientLogger.error('something went wrong loading', err);
      }
    });
  }

  login = async ({ email, password }) => {
    const { organizationToken } = this.state;

    try {
      const loginResponse = await getApolloClient().mutate({
        mutation: LOGIN_USER_WITH_PASSWORD,
        variables: {
          username: email,
          password,
          organizationToken,
        },
      });

      localStorage.removeItem('onboarding');

      if (organizationToken) {
        this.unsetOrganizationToken();
      }

      const user = loginResponse?.data?.loginUserWithPassword;

      if (user?.lastSelectedLanguage) {
        await this.changeLanguage(user.lastSelectedLanguage);
      }

      return user;
    } catch (error) {
      const isErrorHandled = this.onLoginError(error);
      if (!isErrorHandled) {
        return { error: this.getAuthErrorReason(error) };
      }
    }
  };

  logout = async () => {
    await logoutUser();
  };

  createAccount = (params, autoGetUser = true) => {
    const { organizationToken } = this.state;

    return new Promise((resolve, reject) => {
      const followedOpportunities = get(params, 'profile.jobOpportunities', []).map(
        ({ opportunityId, opportunityName, skillsGap }) => ({
          opportunityId,
          opportunityName,
          skillsGap,
          type: 'market',
        })
      );

      const skillsToValidate = get(params, 'profile.validateSkills', []).map(
        ({ _id: skillId, synonymId }) => ({
          skillId,
          synonymId,
        })
      );

      getApolloClient()
        .mutate({
          mutation: CREATE_USER_WITH_PASSWORD,
          variables: {
            email: params.email,
            password: params.password,
            firstName: params.profile.firstName,
            lastName: params.profile.lastName,
            nationality: params.profile.nationality,
            workPermit: params.profile.workPermit,
            followedOpportunities,
            skillsToValidate,
            organizationToken,
          },
        })
        .then(() => {
          localStorage.removeItem('onboarding');

          if (organizationToken) {
            this.unsetOrganizationToken();
          }

          if (!autoGetUser) {
            resolve(true);
          }

          this.getCurrentUser()
            .then(resolve)
            .catch(reject);
        })
        .catch(error => {
          reject(this.getAuthErrorReason(error));
        });
    });
  };

  changePassword = ({ oldPassword, newPassword }) => {
    return new Promise((resolve, reject) => {
      getApolloClient()
        .mutate({
          mutation: CHANGE_USER_PASSWORD,
          variables: {
            oldPassword,
            newPassword,
          },
        })
        .then(resolve)
        .catch(error => {
          reject(this.getAuthErrorReason(error));
        });
    });
  };

  forgotPassword = params => {
    return new Promise((resolve, reject) => {
      getApolloClient()
        .mutate({
          mutation: REQUEST_RESET_PASSWORD_TOKEN,
          variables: {
            email: params.email,
          },
        })
        .then(({ data }) => resolve(data?.requestResetPasswordToken))
        .catch(error => {
          reject(this.getAuthErrorReason(error));
        });
    });
  };

  resetPassword = ({ token, password }) => {
    return new Promise((resolve, reject) => {
      getApolloClient()
        .mutate({
          mutation: RESET_USER_PASSWORD,
          variables: {
            token,
            password,
          },
        })
        .then(() => {
          this.getCurrentUser()
            .then(resolve)
            .catch(reject);
        })
        .catch(error => {
          reject(this.getAuthErrorReason(error));
        });
    });
    // eslint-disable-next-line no-else-return
  };

  updateProfile = async options => {
    const { currentUser } = this.state;
    const handledOptions = options;

    const optimisticData = {
      ...currentUser,
    };

    Object.keys(options).forEach(field => {
      optimisticData.profile[field] = options[field];
    });

    return getApolloClient().mutate({
      mutation: USER_PROFILE_UPDATE,
      variables: {
        record: {
          ...handledOptions,
          _id: currentUser._id,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        userProfileUpdate: {
          __typename: 'UserUpdateProfilePayload',
          recordId: currentUser._id,
          record: {
            __typename: 'User',
            ...optimisticData,
          },
        },
      },
      update: () => {
        this.getCurrentUser();
      },
    });
  };

  updateAvatar = async file => {
    const { currentUser } = this.state;

    const mutation = await getApolloClient().mutate({
      mutation: USER_UPDATE_AVATAR,
      variables: {
        file,
      },
      update: (proxy, { data: { userAvatarUpdate } }) => {
        const avatar = userAvatarUpdate;

        try {
          const fragment = gql`
            fragment UserAvatarField on User {
              profile {
                avatar
              }
            }
          `;

          const currentUserWithNewAvatar = {
            ...currentUser,
            profile: {
              ...currentUser.profile,
              avatar,
            },
          };

          proxy.writeFragment({
            id: `User:${currentUser._id}`,
            fragment,
            data: {
              __typename: 'User',
              profile: {
                __typename: 'UserProfile',
                avatar,
              },
            },
          });

          this.setState({ currentUser: currentUserWithNewAvatar });
        } catch (error) {
          clientLogger.error(error);
        }
      },
    });

    return mutation;
  };

  onSuccessfulCustomOnboarding = async () => {
    return getApolloClient().mutate({
      mutation: UPDATE_USER_ONBOARDING_STATUS,
    });
  };

  getIsOnboardingNeeded = () => {
    const pegasusConfig = useConfig();
    const { hasCustomOnboarding, hasNonSSOonboarding } = get(
      pegasusConfig,
      'cms.onboarding',
      false
    );
    const isSsoUser = get(this.state, 'currentUser.isSsoUser');
    const hasOnboarded = get(this.state, 'currentUser.hasOnboarded');

    return getIsOnboardingNeeded({
      isSsoUser,
      hasOnboarded,
      hasNonSSOonboarding,
      hasCustomOnboarding,
    });
  };

  // updateNotification = notification => {
  //   const { __typename, ...notificationWithoutTypename } = notification;
  //   getApolloClient().mutate({
  //     mutation: gql`
  //       mutation UPDATE_USER_NOTIFICATION($notification: NotificationInput!) {
  //         updateUserNotification(notification: $notification) {
  //           id
  //           text
  //           time
  //           isNew
  //           ctaText
  //         }
  //       }
  //     `,
  //     variables: { notification: notificationWithoutTypename },
  //     refetchQueries: [{ query: GET_VIEWER }],
  //   });
  // };

  unsetOrganizationToken = () => this.setState({ organizationToken: null });

  render() {
    const { state } = this;
    const { children } = this.props;

    return (
      <UserContext.Provider
        value={{
          state,
          logout: this.logout,
          login: this.login,
          createAccount: params => this.createAccount(params, true),
          forgotPassword: this.forgotPassword,
          resetPassword: this.resetPassword,
          updateProfile: this.updateProfile,
          updateAvatar: this.updateAvatar,
          changePassword: this.changePassword,
          onSuccessfulCustomOnboarding: this.onSuccessfulCustomOnboarding,
          getIsOnboardingNeeded: this.getIsOnboardingNeeded,
          unsetOrganizationToken: this.unsetOrganizationToken,
          generateAdminViewToken: this.generateAdminViewToken,
          updateNotification: this.updateNotification,
          getCurrentUser: this.getCurrentUser,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

export const withUser = Children => {
  const WrappedComponent = props => (
    <UserContext.Consumer>{data => <Children {...props} user={data} />}</UserContext.Consumer>
  );

  return WrappedComponent;
};

UserProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUserContext = () => useContext(UserContext);

export const useUserId = () => {
  const userInfo = useContext(UserContext);
  return get(userInfo, 'state.currentUser._id');
};

export const UserProvider = withTranslation('common')(withRouter(UserProviderComponent));
